'use strict';

angular.module('model.User', [
    'Devise',
    'model.Account',
    'model.AccountTransfer',
    'model.AuthenticationFactor',
    'model.BankAccount',
    'model.BrokerageAccount',
    'model.CombinedAccount',
    'model.Document',
    'model.Goal',
    'model.InvestmentManagementAgreement',
    'model.KnowYourClient',
    'model.Notification',
    'model.Person',
    'ram',
    'service.riskAppetiteFieldNames'
  ])
  .factory('User', [
    '$q',
    '$http',
    'ram',
    'config',
    'CombinedAccount',
    'Auth',
    'riskAppetiteFieldNames',
    'Account',
    userFactory
  ]);

function userFactory($q, $http, ram, config, CombinedAccount, Auth, riskAppetiteFieldNames, Account) {

  /**
   * Constructor for User models built on ram models.
   * @class
   */
  var User = new ram.Collection('User', {
    accessors: ['password', 'passwordConfirmation', 'currentPassword', 'promoCode', 'updatedAt'],
    bind: ['age', 'netWorth', 'investorType', 'fullName', 'closeProfile'],
    hasMany: {
      accounts: 'Account',
      viewableAccounts: 'Account',
      bankAccounts: 'BankAccount',
      brokerageAccounts: 'BrokerageAccount',
      documents: 'Document',
      goals: 'Goal',
      investmentManagementAgreements: 'InvestmentManagementAgreement',
      knowYourClients: 'KnowYourClient',
      notifications: 'Notification',
      promotionRedemptions: 'PromotionRedemption'
    },
    hasOne: {
      person: 'Person',
      investmentManagementAgreement: 'InvestmentManagementAgreement',
      knowYourClient: 'KnowYourClient',
      authenticationFactor: 'AuthenticationFactor'
    },
    associationKeys: {
      viewableAccounts: {
        viewable: true
      }
    },
    schema: config.schemas.User,
    serialize: ['person'],
    resources: {
      default: new ram.resources.Http('/api/users/:id.json'),
      cookie: new ram.resources.Cookie('users')
    }
  });

  User.currentUser = function(options) {
    options = options || {};
    var deferred = $q.defer();
    var errorHandler = function() {
      deferred.resolve();
    };
    Auth.currentUser()
      .then(function(response) {
        User.find({
          id: response.id
        }, options).then(function(user) {
          deferred.resolve(user);
        }, errorHandler);
      }, errorHandler);
    return deferred.promise;
  };

  /**
   * Convert user's birthday to a javascript date object and instantiate a
   * CombinedAccount sub-model for the user.
   * @method initialize
   */
  User.prototype.initialize = function() {

    /**
     * A duck-typed Account that represents the combined properties of a users'
     * accounts.
     */
    this.accountsCombined = new CombinedAccount(this, 'accounts', '-', 'My Accounts');
    this.householdCombined = new CombinedAccount(this, 'viewableAccounts', 'h', 'Household Accounts');

    // viewableAccounts is a virtual relationship. Updating the _fetch method to query the right API.
    this.viewableAccounts._fetch = function(force) {
      var self = this; // jshint ignore:line
      var promise = Account.where({
        viewable: true
      }, {
        force: force
      });
      if (promise !== self._promise) {
        self._promise = promise;
      }
      return promise;
    };
  };

  /**
   * Concatenate the user's first and last name.
   * @method fullName
   *
   * @return {String} User's full name.
   */
  User.prototype.fullName = function() {
    var person = this.person();
    return person && person.fullName();
  };

  /**
   * GetterSetter that aliases user's birthday. Sets birthday to the beginning
   * of the birth year. Gets the number of years since birthday.
   * @method age
   *
   * @param  {Number} val Age of the user
   * @return {Number}
   */
  User.prototype.age = function() {
    var person = this.person();
    return person && person.age.apply(person, arguments);
  };

  /**
   * Calculate user's net worth (assets - liabilities)
   *
   * @method netWorth
   * @return {Number}
   */
  User.prototype.netWorth = function() {
    var financialAssets = this.financialAssets() || 0;
    var nonFinancialAssets = this.nonFinancialAssets() || 0;
    var totalLiabilities = this.totalLiabilities() || 0;
    return financialAssets + nonFinancialAssets - totalLiabilities;
  };

  /**
   * Calculate user's Total Net Worth
   * @method totalNetWorth
   * @return {Number}
   */
  User.prototype.totalNetWorth = function() {
    var liquidAssets = this.liquidAssets() || 0;
    var fixedAssets = this.fixedAssets() || 0;
    var liquidLiabilities = this.liquidLiabilities() || 0;
    var fixedLiabilities = this.fixedLiabilities() || 0;
    return liquidAssets + fixedAssets - liquidLiabilities - fixedLiabilities;
  };

  User.prototype.riskSurveyComplete = function() {
    var self = this;
    var accessors = riskAppetiteFieldNames.fieldNameList;
    return _.all(accessors, function(accessor) {
      return !_.isNaN(parseInt(self[accessor]()));
    });
  };

  User.prototype.financialSituationComplete = function() {
    var self = this;
    var accessors = ['income', 'financialAssets', 'nonFinancialAssets', 'totalLiabilities'];
    return _.all(accessors, function(accessor) {
      return !_.isNaN(parseInt(self[accessor]()));
    });
  };

  User.prototype.closeProfile = function() {
    var self = this;
    return $http.put('/api/users/' + this.id + '/close_profile')
      .then(function() {
        return self.reload();
      });
  };

  User.prototype.sendConfirmation = function() {
    var self = this;
    return $http.post('/users/confirmation', {
      user: {
        email: self.email()
      }
    });
  };

  User.prototype.setPassword = function(newPassword) {
    var self = this;
    return $http.post('/api/users/set_password', {
        password: newPassword
      })
      .then(function() {
        return self.reload();
      });
  };

  User.prototype.riskProfileLabel = function () {
    return this.riskProfile();
  };

  User.prototype.riskCapacityLabel = function () {
    return this.riskCapacity();
  };

  User.prototype.recommendedPortfolioTypeLabel = function () {
    return this.recommendedPortfolioType();
  };

  return User;
}
